import { TableColumnsType, Tag } from 'antd';
import { TableCustom } from '@components/table';
import { globalModalState, salonActiveState } from '@components/state';
import { useRecoilState } from 'recoil';
import { WareHouseDetailTable } from './children/WareHouseDetailTable';
import { useWarehouseQueriesRequest } from '../../@share/hooks';
import { useOutletContext } from 'react-router-dom';
import { getWarehouseInfoByTable, WarehouseInfoByTable } from '@/api/report/warehouse';
import dayjs, { Dayjs } from 'dayjs';
import { useHttpQueries, useHttpQuery } from '@/hooks';
import { currencyFormat, formatDateTime } from '@/utils';
import { useCallback, useMemo, useState } from 'react';
import { getAllProduct } from '@/api';
import { SorterResult } from 'antd/es/table/interface';

export interface DataSource extends Partial<WarehouseInfoByTable> {
	readonly items?: WarehouseInfoByTable[];
	readonly salonName?: string;
	readonly productName?: string;
	readonly productPrice?: number;
}

interface QueryParamsState {
	readonly salonId: number;
	readonly page: number;
	readonly search?: string;
	readonly pageSize: number;
	readonly orderBy?: string;
	readonly startDate?: string;
	readonly endDate?: string;
	readonly orderDirection?: string;
}

export function WarehouseTableReport() {
	const [salon] = useRecoilState(salonActiveState);
	const [globalModal, setGlobalModal] = useRecoilState(globalModalState);
	const { dateRange } = useOutletContext<{ dateRange: [Dayjs, Dayjs] }>();

	const currentDate = dayjs();

	const queryEnable = dateRange[0] <= currentDate;

	const [queryParams, setQueryParams] = useState<QueryParamsState>({
		page: 1,
		pageSize: 20,
		salonId: salon!.id,
	});

	const { data: products, isLoading: productsLoading } = useHttpQuery(getAllProduct, {
		query: {
			...queryParams,
			salonId: salon?.id,
			startDate: formatDateTime(dateRange[0]),
			endDate: formatDateTime(dateRange[1]),
		},
	});

	const productIds = products?.map((p) => p.id);

	const warehouseQueries = useWarehouseQueriesRequest({
		dateRange,
		productIds,
		enable: !!productIds && queryEnable ,
		requestsMeta: [getWarehouseInfoByTable],
	});

	const queryResults = useHttpQueries(warehouseQueries);

	const warehouseLoading = queryResults.some((o) => o.isFetching || o.isLoading);

	let dataSources: DataSource[] = [];

	if (products && products.length > 0 && !warehouseLoading) {
		const warehouseData = queryResults.flatMap((o) => o.data) as WarehouseInfoByTable[];

		dataSources = products.map((p) => {
			const items = warehouseData.filter((w) => w?.productId === p.id);

			const totalImport = items.reduce((s, item) => (s += item.import), 0);
			const totalExport = items.reduce((s, item) => (s += item.export), 0);
			const totalInventory = items.reduce((s, item) => (s += item.inventory), 0);
			const totalValue = items.reduce(
				(s, item) => (s += item?.totalValue ?? item.totalInventory * (p?.originPrice ?? 0)),
				0
			);
			const prevInventory = items.reduce((s, item) => (s += item.prevInventory), 0);

			return {
				items,
				totalValue,
				totalImport,
				totalExport,
				totalInventory,
				prevInventory,
				productName: p?.name,
				productPrice: p?.originPrice ?? 0,
			};
		});
	}

	const showDetail = useCallback(
		(dataSource: DataSource) => {
			setGlobalModal({
				...globalModal,
				isOpen: true,
				width: 850,
				titleTransCode: dataSource.productName,
				content: <WareHouseDetailTable dataSource={dataSource} />,
			});
		},
		[globalModal, setGlobalModal]
	);

	const columns = useMemo<TableColumnsType<DataSource>>(
		() => [
			{
				title: 'Sản phẩm',
				dataIndex: 'productName',
				key: 'productName',
			},
			{
				title: 'Tồn kho đầu kì',
				dataIndex: 'prevInventory',
				key: 'prevInventory',
				width: 250,
				render(value) {
					return currencyFormat(value);
				},
			},
			{
				title: 'Nhập kho',
				dataIndex: 'totalImport',
				key: 'totalImport',
				width: 250,
				render(value) {
					return currencyFormat(value);
				},
				sorter: true,
			},
			{
				title: 'Xuất kho',
				dataIndex: 'totalExport',
				key: 'totalExport',
				width: 150,
				render(value) {
					return currencyFormat(value);
				},
				sorter: true,
			},
			{
				title: 'Tồn kho',
				dataIndex: 'totalInventory',
				key: 'totalInventory',
				render(value) {
					return currencyFormat(value);
				},
				width: 150,
				sorter: true,
			},
			{
				title: 'Giá trị kho hàng',
				dataIndex: 'totalValue',
				key: 'totalValue',
				width: 150,
				render(value) {
					return currencyFormat(value);
				},
			},
			{
				title: 'Liên kết',
				width: 150,
				render: (_, record) => {
					return (
						<a>
							<Tag
								onClick={() => {
									showDetail(record);
								}}
								style={{ borderRadius: 2 }}
								color='default'
							>
								Xem chi tiết
							</Tag>
						</a>
					);
				},
			},
		],
		[showDetail]
	);

	const totalItems = products?.[0]?.totalItem ?? 0;

	const getOrderBy = useCallback((field: string) => {
		switch (field) {
			case 'totalImport':
				return 'IMPORT';

			case 'totalExport':
				return 'EXPORT';

			case 'totalInventory':
				return 'INVENTORY';

			default:
				return undefined;
		}
	}, []);

	return (
		<TableCustom
			scroll={{ x: 800 }}
			titleTableTransCode='Sản phẩm'
			onSearch={(value) => {
				setQueryParams((prev) => ({ ...prev, search: value, page: 1 }));
			}}
			placeholderSearchTransCode='Tìm kiếm sản phẩm'
			showHeader={true}
			columns={columns}
			dataSource={dataSources}
			loading={warehouseLoading || productsLoading}
			pageInfo={{
				totalItems,
				pageSize: 20,
				page: queryParams.page,
				pageCount: Math.floor(totalItems / 20),
			}}
			onPageChange={(nextPage) => {
				setQueryParams((prev) => ({ ...prev, page: nextPage }));
			}}
			onChange={(_, __, sorter) => {
				const sort = sorter as SorterResult<DataSource>;

				setQueryParams((prev) => ({
					...prev,
					orderBy: getOrderBy(sort.field as string),
					orderDirection:
						sort.order === 'ascend'
							? 'asc'
							: sort.order === 'descend'
							? 'desc'
							: undefined,
				}));
			}}
		/>
	);
}
