import { WarehouseInfoByChart } from "@/api/report/warehouse";
import { chartHelper } from "@/helpers";
import { UseQueryResult } from "@tanstack/react-query";
import { Dayjs } from "dayjs";
import { groupBy, map } from "lodash";

export const getWarehouseResourcesByChart = (
	queryResults: UseQueryResult<WarehouseInfoByChart[], Error>[],
	dateRange: [Dayjs, Dayjs]
) => {
	const viewType = chartHelper.getViewTypeChart(dateRange);

	const isLoading = queryResults.some((o) => o.isFetching === true);

	const dataChart: {
		date: string;
		type: string;
		value: number;
	}[] = [];

	const data = groupBy(
		queryResults.flatMap((o) => o.data),
		(o) => o?.date
	);
    
	map(data, (items, date) => {
        let totalImport = 0;
        let totalExport = 0;
        let totalInventory = 0;

        items.forEach(item => {
            totalImport += item?.totalImport ?? 0
            totalExport += item?.totalExport ?? 0
            totalInventory += item?.totalInventory ?? 0
        } )

        const dateLabel = chartHelper.getLabelDateByViewChart(viewType, date)!;

        dataChart.push(
			{
				date: dateLabel,
				value: totalInventory,
				type: 'Tồn kho',
			},
			{
				date: dateLabel,
				value: totalExport,
				type: 'Xuất kho',
			},
			{
				date: dateLabel,
				value: totalImport,
				type: 'Nhập kho',
			}
		);
	});

	return {
		dataChart,
		isLoading,
	};
};
