import { env } from '@env';
import { httpUtil } from '@/framework';

interface ProductGetAllRequest {}

export interface ProductGetAllResponse {
	readonly id: number;
	readonly name: string;
	readonly originPrice: number;
	readonly totalItem: number;
}

export const getAllProduct = httpUtil.createHttpRequestMeta<
	ProductGetAllRequest,
	ProductGetAllResponse[]
>({
	baseUrl: env.orgServer,
	path: '/products',
	method: 'GET',
	authentication: 'bearer',
});
