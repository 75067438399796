import { login } from '../../../api';
import { FormBase } from '../../base';
import { FlexBox } from '../../layouts';
import { InputField } from '../../inputs/fields/InputField';
import { AppButton } from '../../buttons';
import { useHttpCommand } from '../../../hooks';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useLoginStore } from '../../../hooks/useExistingLogin';
import { Alert, message } from 'antd';
import { errorMsgs } from '@/shared/const/errorMsgs';
interface LoginFormValues {
    readonly email: string;
    readonly password: string;
    readonly rememberMe: boolean;
}

export const LoginForm = () => {
    const navigate = useNavigate();
    const [accountValid, setAccountValid] = useState(true)
    const { mutateAsync, isPending } = useHttpCommand(login);
    const {
        setData: setSaveLogin,
    } = useLoginStore();


    const onSubmit = React.useCallback(
        async (data: LoginFormValues) => {

            await mutateAsync(
				{
					body: {
						email: data.email,
						password: data.password,
					},
				},
				{
					async onSuccess(response) {
						setSaveLogin(response);
						navigate('/');
					},
					onError(error) {
                        const errorMsg = errorMsgs[error.message];
                        
                        message.error({
                            content: errorMsg ?? 'Lỗi không xác định!!!',
                        });

                        if (error.message === 'ACCOUNT_NOT_FOUND') {
                            setAccountValid(false)
						}
					},
				}
			);
        },
        [mutateAsync, navigate, setSaveLogin]
    );
    return (
        <FormBase onSubmit={onSubmit}>
            <FlexBox direction='column' gap={22}>
			{!accountValid && <Alert message={errorMsgs['ACCOUNT_NOT_FOUND']} type='error'/>}

                <FlexBox direction='column'>
                    <InputField
                        name='email'
                        label=""
                        placeholder="Email"
                    />
                </FlexBox>
                <FlexBox direction='column'>
                    <InputField
                        type='password'
                        name='password'
                        label=''
                        placeholder='Password'
                    />
                </FlexBox>
                <AppButton
                    loading={isPending}
                    htmlType='submit'
                    type='primary'
                    text='Login'
                />
            </FlexBox>
        </FormBase>
    );
};
