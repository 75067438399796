import { Avatar, Card, Spin } from 'antd';
import { Block, FlexBox } from '@components/layouts';
import { Dayjs } from 'dayjs';
import { IconGrowDown, IconGrowUp, IconRevenue } from '@components/icons';
import { IconTotalClient } from '@components/icons/IconTotalClient';
import { IconTotalNewClient } from '@components/icons/IconTotalNewClient';
import { IconTotalRetentionClient } from '@components/icons/IconTotalRetentionClient';
import { IconTotalWalkInClient } from '@components/icons/IconTotalWalkInClient';
import { Text } from '@components/typo';
import { useMemo } from 'react';
import { useHttpQueries } from '@/hooks';
import { LoadingOutlined } from '@ant-design/icons';
import { getCustomerOverviewStates } from './@share/utils';
import {
	getCustomerNewOverview,
	getCustomerReturnOverview,
	getCustomerRevenueOverview,
	getCustomerTotalOverview,
	getCustomerWalkInOverview,
} from '@/api';
import { useCustomerQueriesRequest } from '../../@share/hooks';
import { dateHelper } from '@/helpers';

interface ReportCustomerOverviewProps {
	readonly dateRange: [Dayjs, Dayjs];
	readonly salonBranchId?: number;
}

export const ReportCustomerOverview = (props: ReportCustomerOverviewProps) => {
	const { dateRange, salonBranchId } = props;

	const prevDateRange = useMemo(
		() => dateHelper.getDateRangePrevious(dateRange[0], dateRange[1]) as [Dayjs, Dayjs]
	,[dateRange]);

	const overviewQueries = useCustomerQueriesRequest({
		dateRange,
		requestsMeta: [
			getCustomerTotalOverview,
			getCustomerNewOverview,
			getCustomerReturnOverview,
			getCustomerWalkInOverview,
			getCustomerRevenueOverview,
		],
		salonBranchId,
	});

	const overviewPrevQueries = useCustomerQueriesRequest({
		dateRange: prevDateRange,
		requestsMeta: [
			getCustomerTotalOverview,
			getCustomerNewOverview,
			getCustomerReturnOverview,
			getCustomerWalkInOverview,
			getCustomerRevenueOverview,
		],
		salonBranchId,
	});

	const overViewQueriesResult = useHttpQueries(overviewQueries);
	const overViewPrevQueriesResult = useHttpQueries(overviewPrevQueries);

	const { revenueTotal, customerVisit, customerTotal, customersComBack, customersNew } =
		getCustomerOverviewStates(overViewQueriesResult);

	const {
		revenueTotal: revenuePrevTotal,
		customerVisit: customerPrevVisit,
		customerTotal: customerPrevTotal,
		customersComBack: customersPrevComBack,
		customersNew: customersPrevNew,
	} = getCustomerOverviewStates(overViewPrevQueriesResult);

	const customerOverviews = useMemo(
		() => [
			{
				label: 'Tổng số khách hàng',
				value: customerTotal.value,
				color: '#EFF4FF',
				icon: <IconTotalClient />,
				isLoading: customerTotal.isLoading,
				prevValue: customerPrevTotal.value,
			},
			{
				label: 'Khách hàng mới',
				value: customersNew.value,
				color: '#FEF3F2',
				icon: <IconTotalNewClient />,
				isLoading: customersNew.isLoading,
				prevValue: customersPrevNew.value,
			},
			{
				label: 'Khách hàng quay lại',
				value: customersComBack.value,
				color: '#ECFDF3',
				icon: <IconTotalRetentionClient />,
				isLoading: customersComBack.isLoading,
				prevValue: customersPrevComBack.value,
			},
			{
				label: 'Khách hàng vãng lai',
				value: customerVisit.value,
				color: '#FFFAEB',
				icon: <IconTotalWalkInClient />,
				isLoading: customerVisit.isLoading,
				prevValue: customerPrevVisit.value,
			},
			{
				label: 'Tổng doanh thu',
				value: revenueTotal.value,
				type: 'revenue',
				color: '#EFF4FF',
				icon: <IconRevenue />,
				isLoading: revenueTotal.isLoading,
				prevValue: revenuePrevTotal.value,
			},
		],
		[
			customerTotal.value,
			customerTotal.isLoading,
			customerPrevTotal.value,
			customersNew.value,
			customersNew.isLoading,
			customersPrevNew.value,
			customersComBack.value,
			customersComBack.isLoading,
			customersPrevComBack.value,
			customerVisit.value,
			customerVisit.isLoading,
			customerPrevVisit.value,
			revenueTotal.value,
			revenueTotal.isLoading,
			revenuePrevTotal.value,
		]
	);

	return (
		<div className='overview-layout'>
			{customerOverviews.map((o, i) => {
				const gdp = ((o.value - o.prevValue) / o.prevValue) * 100;

				const isShow = o.prevValue !== 0;

				return (
					<Card key={i}>
						<FlexBox gap={8} alignItems='center' flex='none'>
							<Avatar style={{ backgroundColor: o.color }} size={42} icon={o.icon} />
							<FlexBox direction='column'>
								<Text maxLines={1} maxWidth={200} fontWeight={600} color='#8f9bb2'>
									{o.label}
								</Text>
								{!o.isLoading ? (
									<FlexBox alignItems='center' gap={4} flexWrap='wrap'>
										<Block>
											<Text color='#101828' fontWeight='700' fontSize={18}>
												{o.value.toLocaleString('en-US')}
											</Text>
										</Block>
										<FlexBox alignItems='center'>
											<Text
												fontWeight={600}
												color={gdp >= 0 ? '#56C22D' : '#F5222D'}
											>
												({isShow ? `${gdp.toFixed(2)}%` : ' - '})
											</Text>
											{gdp >= 0 ? <IconGrowUp /> : <IconGrowDown />}
										</FlexBox>
									</FlexBox>
								) : (
									<Spin indicator={<LoadingOutlined spin />} />
								)}
							</FlexBox>
						</FlexBox>
					</Card>
				);
			})}
		</div>
	);
};
