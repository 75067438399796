import dayjs, { Dayjs } from 'dayjs';
import { Card } from 'antd';
import { Block, Spacer } from '@components/layouts';
import { Column } from '@ant-design/charts';
import { useOutletContext } from 'react-router-dom';
import { useWarehouseQueriesRequest } from '../../@share/hooks';
import { getWarehouseInfoByChart } from '@/api/report/warehouse';
import { useHttpQueries } from '@/hooks';
import { getWarehouseResourcesByChart } from './@share/utils';
import { formatDateTime } from '@/utils';

interface ColumnData {
	readonly xField: string;
	readonly yField: number;
	readonly name?: string;
	readonly color?: string;
	readonly value?: number;
	readonly category?: string;
}
export const WarehouseReportChart = () => {
    const { dateRange } = useOutletContext<{ dateRange: [Dayjs, Dayjs] }>();

	const queries = useWarehouseQueriesRequest({
		dateRange,
		currentDate: formatDateTime(dayjs()),
		requestsMeta: [
			getWarehouseInfoByChart
		],
		applyView: true,
	});

	const queryResults = useHttpQueries(queries);

	const {dataChart, isLoading} = getWarehouseResourcesByChart(queryResults, dateRange)

	const config = {
		data: dataChart,
		xField: 'date',
		yField: 'value',
		colorField: 'type',
		group: true,
		sizeField: 35,
		scrollbar: { x: { ratio: 0.5 } },
		style: {
			radiusTopLeft: 10,
			radiusTopRight: 10,
			insetLeft: 10,
			insetRight: 10,
			fill: (type: ColumnData) => {
				return type.color;
			},
			maxWidth: 40,
		},
		scale: {
			x: {
				type: 'band',
			},
			color: {
				range: ['#004EEB', '#40A9FF', '#91D5FF'],
			},
			width: 10,
		},
		legend: {
			color: {
				layout: {
					justifyContent: 'flex-end',
					flexDirection: 'column',
					flexWrap: 'wrap',
					alignContent: 'flex-end',
					alignItems: 'flex-end',
				},
				itemMarker: 'circle',
				itemWidth: '100%',
			},
		},
		tooltip: { channel: 'y', valueFormatter: (d: number) => d.toLocaleString('en-US') },
	};

	return (
		<Block overflow='hidden' maxWidth='100%'>
			<Spacer top={30}>
				<Card>
					<Column {...config} loading={isLoading}/>
				</Card>
			</Spacer>
		</Block>
	);
}
