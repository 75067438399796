import { FormBase } from '@components/base';
import { Block, FlexBox } from '@components/layouts';
import { Header } from '@components/typo';
import { WarehouseReportOverview } from './children/warehouse-overview-report';
import { WarehouseReportChart } from './children/warehouse-chart-report';
import { useMemo } from 'react';
import { chartHelper } from '@/helpers';
import { useOutletContext } from 'react-router-dom';
import { Dayjs } from 'dayjs';
import { WarehouseTableReport } from './children/warehouse-table-report';
import { useRecoilState } from 'recoil';
import { salonActiveState } from '@/components/state';

export function WarehouseReport() {
    const [salon] = useRecoilState(salonActiveState);

    const { dateRange } = useOutletContext<{ dateRange: [Dayjs, Dayjs] }>();

	const isShowChart = useMemo(() => {
		const viewType = chartHelper.getViewTypeChart(dateRange);

		return typeof viewType !== 'undefined';
	}, [dateRange]);

	return (
		<FormBase onSubmit={() => {}}>
			<Block backgroundColor='white' className='tab-content'>
				<FlexBox direction='column' gap={20}>
					<FlexBox alignItems='center'>
						<Header level={3}>Báo cáo kho hàng</Header>
						<FlexBox />
					</FlexBox>
					<WarehouseReportOverview/>
					{isShowChart && <WarehouseReportChart/>}
					{salon && <WarehouseTableReport />}
				</FlexBox>
			</Block>
		</FormBase>
	);
}
