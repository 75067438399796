import { useRecoilState } from "recoil";
import {  accountInfoState, salonActiveState } from "../../components/state/index.ts";
import { Block } from "../../components/layouts/index.ts";
import { useCallback, useEffect, useState } from "react";
import { Tabs } from "antd";
import type { Tab } from 'rc-tabs/lib/interface.d.ts'
import './report.scss';
import { Dayjs } from "dayjs";
import { HeaderReport } from "./@share/HeaderReport.tsx";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { dateHelper } from "@/helpers/dateHelper.ts";
import { Salon } from "@/api/index.ts";

const warehousePathname = '/report/warehouse';

const tabItems: Tab[] = [
		{
			key: '/report/revenue',
			label: 'Doanh thu',
		},
		{
			key: '/report/customer',
			label: 'Khách hàng',
		},
		{
			key: '/report/booking',
			label: 'Lịch hẹn',
		},
 ];

function Report() {
    const dateRangeFromQueryParams = dateHelper.getDateRangeFromQueryParams();
    const salonIdFromQueryParams = Number(new URLSearchParams(window.location.search).get('salonId'));

    const [tabs, setTabs] = useState<Tab[]>(tabItems);

    const location = useLocation();
    const navigate = useNavigate();

    const [currentAccount] = useRecoilState(accountInfoState);

    const [salonActive, setSalonActive] = useRecoilState(salonActiveState);
    const [dateRange, setDateRange] = useState<[Dayjs, Dayjs]>(dateRangeFromQueryParams);

    useEffect(() => {
        if (salonIdFromQueryParams) {
            const salon = currentAccount?.salons?.find(salon => salon.id === salonIdFromQueryParams);
            setSalonActive(salon ??  null);
        } else if(currentAccount?.salons?.length === 1) {
            setSalonActive(currentAccount?.salons[0])
        }
    }, [salonIdFromQueryParams, setSalonActive, currentAccount]);

    const handleChangeDate = useCallback((dateRange: [Dayjs, Dayjs]) => {
		const queryParams = new URLSearchParams(window.location.search);
		queryParams.set('dateRange', dateRange.map((dayjs) => dayjs.toISOString()).join(','));
		window.history.replaceState(
			null,
			'',
			`${window.location.pathname}?${queryParams.toString()}`
		);
        setDateRange(dateRange);
    },[]);

    const handleChangeSalon = useCallback((salon?: Salon | null) => {
        const queryParams = new URLSearchParams(window.location.search);
        queryParams.set('salonId', salon?.id.toString() ?? '');
        window.history.replaceState(
            null,
            '',
            `${window.location.pathname}?${queryParams.toString()}`
        );

        setSalonActive(salon ?? null);
    },[setSalonActive]);

    const handleTabChange = useCallback((key: string) => {
        const queryParams = new URLSearchParams(window.location.search);
        queryParams.set('salonId', salonActive?.id.toString() ?? '');
        queryParams.set('dateRange', dateRange.map(dayjs => dayjs.toISOString()).join(','));
        navigate(`${key}?${queryParams.toString()}`);
    },[dateRange, salonActive, navigate]);

    useEffect(() => {
        const isHasWarehouse = tabs.find((o) => o.key === warehousePathname);
        const isWarehouseTab = location.pathname === warehousePathname;

        if (!salonActive) {
            if (isWarehouseTab) {
				handleTabChange('/report/booking');
			}
            
            if (isHasWarehouse) {
                setTabs(tabs.filter((o) => o.key !== warehousePathname));
            }
        }

        if (salonActive) {
            if (!isHasWarehouse) {
				setTabs((prev) => [
					...prev,
					{
						key: warehousePathname,
						label: 'Kho hàng',
					},
				]);
			}

			if (isWarehouseTab) {
				handleTabChange(warehousePathname);
			}
		}

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [handleTabChange, salonActive])
    
    return (
        <Block>
            <HeaderReport
                defaultValue={salonActive?.id} 
                dateRange={dateRange} 
                onChangeDate={handleChangeDate} 
                onChangeSalon={handleChangeSalon}
                salonActive={salonActive}
            />
            <Tabs 
                defaultActiveKey={location.pathname} 
                activeKey={location.pathname}
                items={tabs} 
                onChange={handleTabChange} 
                destroyInactiveTabPane
            />
            <Outlet context={{ dateRange }} />
        </Block >
    )
}
export default Report