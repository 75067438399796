import { Text } from '@components/typo';
import { Avatar, Card, Spin } from 'antd';
import dayjs, { Dayjs } from 'dayjs';
import { useMemo } from 'react';
import { Block, FlexBox } from '@components/layouts';
import { IconGrowDown, IconGrowUp, IconRevenue, TotalImportIconWareHouse } from '@components/icons';
import { ImportIconWareHouse } from '@components/icons/ImportIconWareHouse';
import { ExportIconWareHouse } from '@components/icons/ExportIconWareHouse';
import { useOutletContext } from 'react-router-dom';
import { getWarehouseOverviewInfo, getWarehouseValueOverview } from '@/api/report/warehouse';
import { useWarehouseQueriesRequest } from '../../@share/hooks';
import { useHttpQueries } from '@/hooks';
import { getWarehouseOverviewStates } from './@share/utils';
import { dateHelper } from '@/helpers';
import { LoadingOutlined } from '@ant-design/icons';

export const WarehouseReportOverview = () => {
	const { dateRange } = useOutletContext<{ dateRange: [Dayjs, Dayjs] }>();

	const currentDate = dayjs();

	const prevDateRange = useMemo(
		() => dateHelper.getDateRangePrevious(dateRange[0], dateRange[1]) as [Dayjs, Dayjs],
		[dateRange]
	);

	const queryEnable = dateRange[0] <= currentDate

	const warehouseValueQueries = useWarehouseQueriesRequest({
		dateRange,
		enable: queryEnable,
		requestsMeta: [getWarehouseValueOverview],
	});

	const prevWarehouseValueQueries = useWarehouseQueriesRequest({
		dateRange: prevDateRange,
		enable: queryEnable,
		requestsMeta: [getWarehouseValueOverview],
	});

	const warehouseValueResult = useHttpQueries(warehouseValueQueries);

	const prevWarehouseValueResult = useHttpQueries(prevWarehouseValueQueries);

	const warehouseValueLoading = warehouseValueResult.some(o => o.isFetching || o.isFetching); 

	const warehouseValue = warehouseValueResult.reduce((s, item) => s += item.data?.warehouseValue ?? 0, 0)

	const prevWarehouseValue = prevWarehouseValueResult.reduce(
		(s, item) => (s += item.data?.warehouseValue ?? 0),
		0
	);

	const overviewQueries = useWarehouseQueriesRequest({
		dateRange,
		enable: queryEnable,
		requestsMeta: [getWarehouseOverviewInfo],
	});

	const overviewPrevQueries = useWarehouseQueriesRequest({
		dateRange: prevDateRange,
		enable: queryEnable,
		requestsMeta: [getWarehouseOverviewInfo],
	});

	const overViewQueriesResult = useHttpQueries(overviewQueries);

	const overViewPrevQueriesResult = useHttpQueries(overviewPrevQueries);

	const {
		isLoading, totalExport, totalImport, totalInventory
	} = getWarehouseOverviewStates(overViewQueriesResult);


	const {
		totalExport: prevExport,
		totalImport: prevImport,
		totalInventory: prevInventory,
	} = getWarehouseOverviewStates(overViewPrevQueriesResult);

	const WarehouseOverviews = useMemo(() => {

		return [
			{
				label: 'Tổng tồn kho',
				value: totalInventory,
				prevValue: prevInventory,
				color: '#EFF4FF',
				isLoading,
				icon: <ImportIconWareHouse />,
			},
			{
				label: 'Tổng xuất kho',
				value: totalExport,
				prevValue: prevExport,
				color: '#FEF3F2',
				isLoading,
				icon: <ExportIconWareHouse />,
			},
			{
				label: 'Tổng nhập kho',
				value: totalImport,
				prevValue: prevImport,
				color: '#FEF3F2',
				isLoading,
				icon: <TotalImportIconWareHouse />,
			},
			{
				label: 'Tổng giá trị kho',
				type: 'value',
				value: warehouseValue,
				prevValue: prevWarehouseValue,
				color: '#FFF1F0',
				isLoading: warehouseValueLoading,
				icon: <IconRevenue color='#F04438' />,
			},
		];
	}, [totalInventory, prevInventory, isLoading, totalExport, prevExport, totalImport, prevImport, warehouseValue, prevWarehouseValue, warehouseValueLoading]);

	return (
		<div className='overview-layout'>
			{WarehouseOverviews.map((o, i) => {
				const gdp = ((o.value - o.prevValue) / o.prevValue) * 100;

				const isShow = o.prevValue !== 0;

				return (
					<Card key={i}>
						<FlexBox gap={8} alignItems='center' flex='none'>
							<Avatar style={{ backgroundColor: o.color }} size={42} icon={o.icon} />
							<FlexBox direction='column'>
								<Text maxLines={1} maxWidth={200} fontWeight={600} color='#8f9bb2'>
									{o.label}
								</Text>
								{!o.isLoading ? (
									<FlexBox alignItems='center' gap={4} flexWrap='wrap'>
										<Block>
											<Text color='#101828' fontWeight='700' fontSize={18}>
												{o.value.toLocaleString('en-US')}
											</Text>
										</Block>
										<FlexBox alignItems='center'>
											<Text
												fontWeight={600}
												color={gdp >= 0 ? '#56C22D' : '#F5222D'}
											>
												({isShow ? `${gdp.toFixed(2)}%` : ' - '})
											</Text>
											{gdp >= 0 ? <IconGrowUp /> : <IconGrowDown />}
										</FlexBox>
									</FlexBox>
								) : (
									<Spin indicator={<LoadingOutlined spin />} />
								)}
							</FlexBox>
						</FlexBox>
					</Card>
				);
			})}
		</div>
	);
};
