import { Dayjs } from 'dayjs';
import { useMemo } from 'react';
import { HttpRequestMeta } from '@/framework';
import { chartHelper } from '@/helpers';
import { useSalonIds } from '@/hooks';
import { ViewType } from '@/shared/enum';
import { HttpQueries } from '@/hooks';
import { formatDateTime } from '@/utils';

interface Params<TRequest, TResponse> {
	readonly requestsMeta: HttpRequestMeta<TRequest, TResponse>[];
	readonly dateRange: [Dayjs, Dayjs];
	readonly salonBranchId?: number;
	readonly applyView?: boolean;
}

export const useCustomerQueriesRequest = <TRequest, TResponse>({
	dateRange,
	applyView,
	requestsMeta,
	salonBranchId,
}: Params<TRequest, TResponse>) => {
	const salons = useSalonIds(salonBranchId);

	const viewType = useMemo(
		() => (applyView ? chartHelper.getViewTypeChart(dateRange) : undefined),
		[applyView, dateRange]
	);

	const noDivideCusQueries = useMemo(
		() =>
			salons
				.filter((s) => !s.isDivideCustomer)
				.flatMap((salon) =>
					requestsMeta.map((requestMeta) =>
						getHttpQuery({
							viewType,
							dateRange,
							requestMeta,
							salonId: salon.id,
						})
					)
				),
		[dateRange, requestsMeta, salons, viewType]
	);

	const divideCusQueries = useMemo(
		() =>
			salons
				.filter((s) => s.isDivideCustomer)
				.map((salon) =>
					salon.salonBranchIds.map((branchId) =>
						requestsMeta.map((requestMeta) =>
							getHttpQuery({
								viewType,
								dateRange,
								requestMeta,
								salonId: salon.id,
								salonBranchId: branchId,
							})
						)
					)
				)
				.flat(2),
		[dateRange, requestsMeta, salons, viewType]
	);

	return [...noDivideCusQueries, ...divideCusQueries];
};


export const getHttpQuery = <TRequest, TResponse>({
	salonId,
	viewType,
	dateRange,
	requestMeta,
	salonBranchId,
}: {
	salonId: number;
	viewType?: ViewType;
	salonBranchId?: number;
	dateRange: [Dayjs, Dayjs];
	requestMeta: HttpRequestMeta<TRequest, TResponse>;
}) => {
	return {
		requestMeta,
		requestData: {
			query: {
				salonId,
				viewType,
				salonBranchId,
				startDate: formatDateTime(dateRange[0]),
				endDate: formatDateTime(dateRange[1]),
			},
		},
	} as HttpQueries<TRequest, TResponse>;
};
