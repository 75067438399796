import { env } from '@env';
import { httpUtil } from '@/framework';
import { GetWarehouseReportRequest } from '../Types';

export interface WarehouseOverviewInfo {
	readonly totalImport: number;
	readonly totalExport: number;
	readonly totalInventory: number;
}


export const getWarehouseOverviewInfo = httpUtil.createHttpRequestMeta<
	GetWarehouseReportRequest,
	WarehouseOverviewInfo
>({
	baseUrl: env.orgServer,
	path: '/warehouses/overview/info',
	method: 'GET',
	authentication: 'bearer',
});
