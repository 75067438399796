import { Navigate, Outlet } from "react-router-dom";
import { useRecoilState } from "recoil";
import { accountInfoState, salonsState } from "../state";
import { useHttpCommand } from "../../hooks";
import { getAccountCurrent } from "../../api";
import { useEffect, useState } from "react";
import { Spin } from "antd";

export const ProtectedRoute = () => {
  const [, setCurrentAccount] = useRecoilState(accountInfoState);
  const [, setSalons] = useRecoilState(salonsState);

  const { mutateAsync: getAccount } = useHttpCommand(getAccountCurrent);

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchInitialize = async () => {
      try {
        const account = await getAccount({});

        setCurrentAccount(account);
        setSalons(account.salons);

      } catch (error) {
        console.error(error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchInitialize();
    
  }, [getAccount, setSalons, setCurrentAccount]);

  const [currentUser] = useRecoilState(accountInfoState);

  if (isLoading) {
    return (
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <Spin size="large" />
      </div>
    );
  }

  return currentUser ? <Outlet /> : <Navigate to="/auth/login" replace />;
};
