import { TableColumnsType } from "antd";
import { TableCustom } from "@components/table";
import { DataSource } from "../WarehouseTableReport";
import { salonActiveState } from "@/components/state";
import { useRecoilState } from "recoil";
import { currencyFormat } from "@/utils";

interface Props {
	readonly dataSource: DataSource;
}

export function WareHouseDetailTable({dataSource: {items, productPrice}}: Props) {
    const [salon] = useRecoilState(salonActiveState);

    const salonBranches = salon?.salonBranches || [];

    const data: DataSource[] = items!.map(o => {
        const salonName = salonBranches.find(s => s.id === o.salonBranchId)?.name ?? '_'

        return {
            salonName,
			totalExport: o.totalExport,
			totalImport: o.totalImport,
			totalInventory: o.totalInventory,
			prevTotalInventory: o.prevTotalInventory,
			totalValue: o?.totalValue ?? o.totalInventory * productPrice!,
		};
    }) ?? [];

    const columns: TableColumnsType<DataSource> = [
		{
			title: 'Chi nhánh',
			dataIndex: 'salonName',
			key: 'salonName',
		},
		{
			title: 'Tồn kho đầu kì',
			dataIndex: 'prevTotalInventory',
			key: 'prevTotalInventory',
			render(value) {
				return currencyFormat(value);
			},
		},
		{
			title: 'Nhập kho',
			dataIndex: 'totalImport',
			key: 'totalImport',
			render(value) {
				return currencyFormat(value);
			},
			sorter: (a, b) => a.totalImport! - b.totalImport!,
		},
		{
			title: 'Xuất kho',
			dataIndex: 'totalExport',
			key: 'totalExport',
			render(value) {
				return currencyFormat(value);
			},
			sorter: (a, b) => a.totalExport! - b.totalExport!,
		},
		{
			title: 'Tồn kho',
			dataIndex: 'totalInventory',
			key: 'totalInventory',
			render(value) {
				return currencyFormat(value);
			},
		},
		{
			title: 'Giá trị kho hàng',
			dataIndex: 'totalValue',
			key: 'totalValue',
			width: 150,
			render(value, record) {
				return currencyFormat(value ?? record.totalInventory! * productPrice!);
			},
		},
	];

    return (
        <div style={{ paddingTop: 20 }}>
            <TableCustom
                showHeader={false}
                columns={columns}
                dataSource={data}
                showFooter={false}
            />
        </div>
    )
}
