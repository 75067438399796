import { GetCustomerTotalChartResponse } from '@/api';
import { chartHelper } from '@/helpers';
import { UseQueryResult } from '@tanstack/react-query';
import { Dayjs } from 'dayjs';
import { groupBy, map } from 'lodash';
import { TooltipItemValue } from '@antv/g2';

export const getCustomerTotalChartResources = (
	queryResults: UseQueryResult<GetCustomerTotalChartResponse, Error>[],
	dateRange: [Dayjs, Dayjs]
) => {
	const viewType = chartHelper.getViewTypeChart(dateRange);

	const isLoading = queryResults.some((o) => o.isFetching === true);

	const dataChart: {
		date: string;
		type: string;
		value: number;
	}[] = [];

	const data = groupBy(
		queryResults.flatMap((o) => o.data),
		(o) => o?.date
	);

	map(data, (items, date) => {
		const itemsGroup = groupBy(items, (o) => o?.type);
		map(itemsGroup, (itemsChild, type) => {
			const value = itemsChild.reduce((s, item) => (s += item?.total ?? 0), 0);
			dataChart.push({
				date: chartHelper.getLabelDateByViewChart(viewType, date)!,
				value,
				type: getCustomerType(type),
			});
		});
	});

	return {
		dataChart,
		isLoading,
	};
};

const getCustomerType = (type: string) => {
	switch (type) {
		case 'NEW':
			return 'Khách hàng mới';
		case 'WALK_IN':
			return 'Khách vãng lai';
		case 'RETURN':
			return 'Khách quay lại';
		default:
			return 'Khác';
	}
};

export const getCustomerRevenueChartResources = (
	queryResults: UseQueryResult<GetCustomerTotalChartResponse, Error>[],
	dateRange: [Dayjs, Dayjs]
) => {
	const viewType = chartHelper.getViewTypeChart(dateRange);

	const dataGroup = groupBy(
		queryResults.flatMap((o) => o.data),
		(o) => o?.date
	);

	const data: {
		date: string;
		type: string;
		value: number;
	}[] = [];

	map(dataGroup, (items, date) => {
		const value = items.reduce((s, item) => (s += item?.total ?? 0), 0);
		data.push({
			date: chartHelper.getLabelDateByViewChart(viewType, date)!,
			value,
			type: 'REVENUE',
		});
	});

	return {
		dataRevenue: data,
	};
};

export const getCustomerTotalChartTooltipRender = (
	items: TooltipItemValue[],
	title: string,
	revenue: number
) => {
	return `<div style="padding: 8px; width: 300px; ">
                <div style="margin-bottom: 10px">
                    <span style="color: #333; font-weight: 600; font-size: 16px">${title}</span>
                </div>
                <div style="margin-bottom: 8px">
                    <ul style="margin-left: 0px; list-style-type: none">
                        ${items
							.map((item) => {
								return `
                                    <li style="margin-bottom: 10px; color: blue">
                                        <div style="display: flex; justify-content: space-between">
                                            <div style="display: flex; align-items: center; gap: 5px">
												<div style="height: 8px; width: 8px; background-color: ${item.color}; border-radius: 50%"></div>
												<span style="color: #333; font-weight: 500; font-size: 14px">${item.name} </span>
											</div>
                                            <span style="color: #0A1F44; font-weight: 600; font-size: 14px">${item.value?.toLocaleString('en-US')}</span>
                                        </div>
                                    </li>
                                `;
							})
							.join('')}
                    	<li style="margin-bottom: 5px; color: blue">
                            <div style="display: flex; justify-content: space-between">
								<div style="display: flex; align-items: center; gap: 5px">
									<div style="height: 8px; width: 8px; background-color: #BDC6D9; border-radius: 50%"></div>
									<span style="color: #333; font-weight: 500; font-size: 14px">Tổng doanh thu</span>
								</div>
                                <span style="color: #0A1F44; font-weight: 600; font-size: 14px">${revenue.toLocaleString('en-US')}</span>
                            </div>
                        </li>
                    </ul>
                </div>
            `;
};
