import { WarehouseOverviewInfo } from '@/api/report/warehouse';
import { UseQueryResult } from '@tanstack/react-query';

export const getWarehouseOverviewStates = (
	queriesResult: UseQueryResult<WarehouseOverviewInfo, Error>[]
) => {
	const isLoading = queriesResult.some(o => o.isFetching || o.isLoading)
	const data = queriesResult.flatMap((o) => o.data ?? []);

	const  totalImport = data.reduce((s, item) => s += item.totalImport, 0)
	const  totalExport = data.reduce((s, item) => s += item.totalExport, 0)
	const  totalInventory = data.reduce((s, item) => s += item.totalInventory, 0)

	return {
		isLoading,
		totalImport,
		totalExport,
		totalInventory,
	};
};
