import { env } from '@env';
import { httpUtil } from '@/framework';
import { WarehouseByChartRequest, WarehouseInfoByChart } from './Types';

export const getWarehouseInfoByChart = httpUtil.createHttpRequestMeta<
	WarehouseByChartRequest,
	WarehouseInfoByChart[]
>({
	baseUrl: env.orgServer,
	path: '/warehouses/by-chart',
	method: 'GET',
	authentication: 'bearer',
});
