import { salonActiveState } from '@/components/state';
import { HttpRequestMeta } from '@/framework';
import { chartHelper } from '@/helpers';
import { Dayjs } from 'dayjs';
import { useMemo } from 'react';
import { useRecoilState } from 'recoil';
import { ViewType } from '@/shared/enum';
import { HttpQueries } from '@/hooks';
import { formatDateTime } from '@/utils';

interface Params<TRequest, TResponse> {
	readonly requestsMeta: HttpRequestMeta<TRequest, TResponse>[];
	readonly dateRange: [Dayjs, Dayjs];
	readonly applyView?: boolean;
	readonly currentDate?: string;
	readonly productIds?: number[];
	readonly enable?: boolean
}

export const useWarehouseQueriesRequest = <TRequest, TResponse>({
	dateRange,
	applyView,
	requestsMeta,
	productIds,
	enable,
	currentDate
}: Params<TRequest, TResponse>) => {
	const [salon] = useRecoilState(salonActiveState);

	const viewType = useMemo(
		() => (applyView ? chartHelper.getViewTypeChart(dateRange) : undefined),
		[applyView, dateRange]
	);

	const queries = useMemo(
		() =>
			salon?.salonBranches?.flatMap((branch) =>
				requestsMeta.map((requestMeta) =>
					getHttpQuery({
						enable,
						viewType,
						productIds,
						dateRange,
						currentDate,
						requestMeta,
						salonId: salon.id,
						salonBranchId: branch.id,
					})
				)
			),
		[salon, requestsMeta, enable, viewType, productIds, dateRange, currentDate]
	);

	return queries || [];
};


export const getHttpQuery = <TRequest, TResponse>({
	enable,
	salonId,
	viewType,
	dateRange,
	productIds,
	requestMeta,
	salonBranchId,
	currentDate
}: {
	salonId: number;
	enable?: boolean;
	viewType?: ViewType;
	productIds?: number[];
	salonBranchId?: number;
	dateRange: [Dayjs, Dayjs];
	currentDate?: string;
	requestMeta: HttpRequestMeta<TRequest, TResponse>;
}) => {
	return {
		requestMeta,
		requestData: {
			query: {
				salonId,
				viewType,
				productIds,
				currentDate,
				salonBranchId,
				startDate: formatDateTime(dateRange[0]),
				endDate: formatDateTime(dateRange[1]),
			},
		},
		options: {
			enabled: enable !== undefined ? enable : true,
		},
	} as HttpQueries<TRequest, TResponse>;
};
