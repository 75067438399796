import { env } from '@env';
import { httpUtil } from '@/framework';

interface WarehouseByTableRequest {

}

export interface WarehouseInfoByTable{
	readonly productId: number;
    readonly totalValue?: number
    readonly value?: number;
    readonly totalImport: number;
    readonly import: number;
    readonly totalExport: number;
    readonly export: number;
    readonly salonBranchId: number;
    readonly totalInventory: number;
    readonly inventory: number;
    readonly prevInventory: number;
    readonly prevTotalInventory: number;
}

export const getWarehouseInfoByTable = httpUtil.createHttpRequestMeta<
	WarehouseByTableRequest,
	WarehouseInfoByTable[]
>({
	baseUrl: env.orgServer,
	path: '/warehouses/by-table',
	method: 'GET',
	authentication: 'bearer',
});
