import { env } from '@env';
import { httpUtil } from '@/framework';
import { GetWarehouseReportRequest } from '../Types';

interface WarehouseValueResponse {
	readonly warehouseValue: number;
}

export const getWarehouseValueOverview = httpUtil.createHttpRequestMeta<
	GetWarehouseReportRequest,
	WarehouseValueResponse
>({
	baseUrl: env.orgServer,
	path: '/warehouses/overview/total-value',
	method: 'GET',
	authentication: 'bearer',
});
